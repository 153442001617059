<template>
  <base-view id="article">
    <view-section>
      <div v-if="!article">
        <p>Loading...</p>
      </div>
      <div v-else>
        <h1 class="mt-6 mb-10 text-4xl font-semibold text-center md:text-5xl">
          {{ article.name }}
        </h1>
        <div
          v-html="article.body"
          id="article-content"
          class="mx-auto prose prose-md md:prose-lg"
        ></div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

export default {
  components: {
    BaseView,
    ViewSection
  },
  data() {
    return {
      articleId: parseInt(this.$route.params.articleId)
    };
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.articleId = parseInt(this.$route.params.articleId);
      } else {
        this.articleId = undefined;
      }
    }
  },
  computed: {
    article() {
      return this.$store.getters.articleById(this.articleId);
    }
  }
};
</script>

<style>
#article-content .wysiwyg-font-size-small {
  font-size: small;
}

#article-content .wysiwyg-font-size-small {
  font-size: small;
}

#article-content .wysiwyg-font-size-small {
  font-size: small;
}

#article-content .wysiwyg-underline {
  text-decoration: underline;
}

#article-content img {
  width: 100%;
  height: auto;
  max-width: 40rem;
}

#article-content iframe {
  border-radius: 0.25rem;
  border: 1px solid gainsboro;
  width: 100%;
  max-width: 19rem;
}
</style>
